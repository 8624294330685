import ApiService from './ApiService';

class CardApi extends ApiService {
    constructor() {
        super('card');
    }

    getAllCard(payload) {
        return this.get('', payload);
    }

    getCard({ id, payload }) {
        return this.get(id, payload);
    }

    createCard(payload) {
        return this.post('', payload, { 'Content-Type': 'multipart/form-data' });
    }

    updateCard({ id, payload }) {
        return this.put(id, payload, { 'Content-Type': 'multipart/form-data' });
    }

    deleteCard({ id, payload }) {
        return this.delete(id, payload);
    }
    updateCardStatus({ id, payload }) {
        return this.put(`status/${id}`, payload);
    }
    assignCard(payload) {
        return this.post('assign', payload);
    }
    updateStatusLockCard(payload) {
        const {id, ...otherPayload} = payload;
        return this.put(`${id}/status-lock`, otherPayload)
    }

}

export default CardApi;
