/* eslint-disable indent */
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DateTimeCalendarCustom, ReactNotifications, Table } from '../../components';
import { DELETE_FLAG, MSG, ROLE, STATUS_CARD, STATUS_COMMON, SYSTEM_PATH, TEXT, TOOLTIP } from '../../core/configs/constants';
import { useStore } from '../../core/utils/hook';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import FormatNumber from '../../components/ReactNumberFormat';
import ModalAssign from './components/ModalAssign';
import { toJS } from 'mobx';
import moment from 'moment';
import yup from '../../core/utils/yupValidate';
import { yupResolver } from '@hookform/resolvers/yup';


const StatusCard = {
    WAIT_FOR_DEPOSIT: STATUS_CARD.WAIT_FOR_DEPOSIT,
    NO_OWNER: STATUS_CARD.NO_OWNER,
    NOT_PUBLIC: STATUS_CARD.NOT_PUBLIC,
    OWNING: STATUS_CARD.OWNING,
    SENT_MONEY: STATUS_CARD.SENT_MONEY
}


const CardScreen = observer(props => {

    // other
    const navigate = useNavigate();

    // store
    const {
        cardStore: { getAllCard, cardList, paging, setAttrObservable,
            deleteCard, updateCardStatus, disabledCardList, cardListCanSelect, keepSearchParams, assignCard, defaultPaging },
        cardTypeStore: { getAllCardType, cardTypeList },
        modalStore: { hide, openWarningModal, show },
        authStore: { userInfo }
    } = useStore();

    // state
    const validateSearchSchema = yup.object().shape({
        code: yup
            .string().trim(),
        userId: yup.string().trim(),
        accountId: yup.string().trim(),
        cardTypeId: yup.string(),
        status: yup.string(),
        minTransactionDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        maxTransactionDate: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        })
    })
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors, isSubmitting }, } = useForm({
        resolver: yupResolver(validateSearchSchema),
        mode: 'onChange',
        defaultValues: {
            code: undefined,
            userId: undefined,
            accountId: undefined,
            cardTypeId: undefined,
            status: undefined,
            minTransactionDate: null,
            maxTransactionDate: null
        }
    });
    // save card selected
    const [selectedCards, setSelectedCards] = useState([]);
    // check all

    // lifecycle
    const [isSelectedAllCustom, setIsSelectedAllCustom] = useState(false);

    const selectedRowIds = useMemo(() => {
        const obj = {};
        selectedCards.forEach(item => {
            obj[item.id] = true;
        });
        return obj;
    }, [selectedCards])

    useEffect(() => {
        getAllCardType({
            size: -1,
            status: STATUS_COMMON.ACTIVE.key
        });
        if (keepSearchParams && Object.keys(keepSearchParams).length > 0) {
            reset(keepSearchParams);
            onFetchData(keepSearchParams);
        } else {
            onSearch();
        }
    }, [])

    // modal
    const onShowConfirmDeleteCard = (id) => {
        openWarningModal('このカードを放棄しますが、よろしいですか。', () => onSubmitDelete(id));
    }

    const onChangeStatus = (id, status) => {
        if (status === StatusCard.NOT_PUBLIC.key) {
            return openWarningModal('このカードを非公開にしますが、よろしいですか。', () => onShowUpdateStatus(id, status));
        }
        return openWarningModal('このカードを公開しますが、よろしいですか。', () => onShowUpdateStatus(id, status));
    }

    const onShowModalAssign = () => {
        if (selectedCards?.length > 0) {
            show({
                id: 'modal-alert',
                isOpen: true,
                header: '保有者選択',
                onCancel: () => {
                    setSelectedCards([])
                    hide();
                },
                children: (
                    <ModalAssign handleAssignCard={handleAssignCard}></ModalAssign>
                ),
                type: 'small'
            });
        }
    }

    // function
    const onSearch = (data) => {
        setAttrObservable('paging', defaultPaging);
        setAttrObservable('keepSearchParams', data, true, false);
        setSelectedCards([]);
        getAllCard(data)
    }

    const onFetchData = (tableData) => {
        if (tableData) {
            setAttrObservable('paging', tableData, true, false);
        }
        getAllCard({
            ...getValues(),
            ...tableData,
            ...(getValues('status') == StatusCard.NO_OWNER.key && !tableData?.sortKey ? { sortKey: 'base_rate', sortDir: 'ASC' } : {})
        })
    }

    const onSubmitDelete = async (id, payload = null) => {
        let res;
        res = await deleteCard(id);
        if (res) {
            ReactNotifications('success', MSG['inform.success.delete']);
            onFetchData();
            hide();
        }
    }

    const onShowUpdateStatus = async (id, status) => {
        const res = await updateCardStatus(id, { status });
        if (res) {
            ReactNotifications('success', MSG['inform.success.update']);
            onFetchData();
            hide();
        }
    }

    const onSelectedAllChange = (value) => {
        setSelectedCards(oldCards => {
            let data = oldCards;
            toJS(cardListCanSelect).forEach(userRow => {
                const index = data.findIndex(e => e.id === userRow.id);
                if (value) {
                    if (index < 0) data = [...data, userRow];
                } else {
                    if (index > -1) data = [...data.slice(0, index), ...data.slice(index + 1)];
                }
            })
            return data;
        })
        setIsSelectedAllCustom(value);
    }

    const handleSelectCards = (selectedRows, rows) => {
        const selectedIds = Object.keys(selectedRows);
        if (selectedIds?.length > 0) {
            setSelectedCards(oldCards => {
                let data = oldCards;
                toJS(cardListCanSelect).forEach(userRow => {
                    const index = data.findIndex(e => e.id === userRow.id);
                    if (selectedIds.includes(String(userRow.id))) {
                        if (index < 0) {
                            data = [...data, userRow];
                        }
                    } else {
                        if (index > -1) {
                            data = [...data.slice(0, index), ...data.slice(index + 1)];
                        }
                    }
                })

                const dataIds = data.map(e => e.id);
                if (toJS(cardListCanSelect)?.length > 0) {
                    setIsSelectedAllCustom(toJS(cardListCanSelect).every(e => dataIds.includes(e.id)));
                } else {
                    setIsSelectedAllCustom(false);
                }

                return data;
            })
        } else {
            setSelectedCards([]);
            setIsSelectedAllCustom(false);
        }
    }

    const handleAssignCard = async (value) => {
        let res
        if (selectedCards.length > 0) {
            res = await assignCard({ cardIds: [...selectedCards.map((item) => item?.id)], accountId: +value?.accountId });
        }
        if (res) {
            ReactNotifications('success', MSG['inform.success.assign_card']);
            hide();
            onFetchData();
        }
    }

    // columns
    const columns = [
        {
            Header: 'カードID',
            accessor: 'code',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.code}
                    </div>
                );

            },
            width: '11.5%'
        },
        {
            Header: 'ユーザーID',
            accessor: 'userCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.account?.user?.deleteFlag === DELETE_FLAG?.NOT_DELETED ?
                            <Link to={`${SYSTEM_PATH.USER_MANAGEMENT}/${original?.account?.user?.id}`} target="_blank">
                                {(original?.status === StatusCard.OWNING.key ||
                                    original?.status === StatusCard.SENT_MONEY.key ||
                                    original?.status === StatusCard.WAIT_FOR_DEPOSIT.key) ? original?.account?.user?.code : <></>}
                            </Link> : <div >
                                {(original?.status === StatusCard.OWNING.key ||
                                    original?.status === StatusCard.SENT_MONEY.key ||
                                    original?.status === StatusCard.WAIT_FOR_DEPOSIT.key) ? original?.account?.user?.code : <></>}
                            </div>}
                    </div>

                );
            },
            width: '10.5%'
        },
        {
            Header: 'アカウントID',
            accessor: 'accountCode',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.account?.deleteFlag === DELETE_FLAG?.NOT_DELETED ?
                            <Link to={`/user/account/${original?.account?.id}`} target="_blank">
                                {(original?.status === StatusCard.OWNING.key ||
                                    original?.status === StatusCard.SENT_MONEY.key ||
                                    original?.status === StatusCard.WAIT_FOR_DEPOSIT.key) ? original?.account?.code : <></>}
                            </Link> : <div >
                                {(original?.status === StatusCard.OWNING.key ||
                                    original?.status === StatusCard.SENT_MONEY.key ||
                                    original?.status === StatusCard.WAIT_FOR_DEPOSIT.key) ? original?.account?.code : <></>}
                            </div>}
                    </div>

                );
            },
            width: '10.5%'
        },
        // {
        //     Header: '発行日時',
        //     accessor: 'publicAt',
        //     disableSortBy: true,
        //     Cell: ({ row: { original } }) => {
        //         return (
        //             <div>
        //                 {original?.publicAt ? moment(original?.publicAt).format('YYYY-MM-DD HH:mm') : ''}

        //             </div>
        //         );
        //     },
        //     width: '10.5%'
        // },
        {
            Header: '基準レート (USDT)',
            accessor: 'base_Rate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.baseRate}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'カード価格 (円)',
            accessor: 'card_price',
            disableSortBy: false,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {(original?.cardPrice || original?.cardPrice === 0) ? <FormatNumber statusDisplay='text' value={original?.cardPrice} /> : ''}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'Airdrop (SML)',
            accessor: 'airdrop',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {(original?.airdrop || original?.airdrop === 0) ? <FormatNumber statusDisplay='text' value={original?.airdrop} /> : ''}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: '騰落率',
            accessor: 'variationRate',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                const formatVariationRate = original?.variationRate ? original?.variationRate?.toFixed(2) : 0.00;
                return (
                    <div>
                        {original?.variationRate > 0 ? `+${formatVariationRate}` : formatVariationRate}%
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'カード種類',
            accessor: 'cardType',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original?.cardType}
                    </div>
                );
            },
            width: '9.5%'
        },

        {
            Header: 'カードステータス',
            accessor: 'status',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                // const statusKey = Object.keys(USER_STATUS).filter(e => USER_STATUS[e].key === original?.status)?.[0] ?? null;
                const roleKey = Object.keys(StatusCard).filter(e => StatusCard[e].key === original?.status)?.[0] ?? null;

                return (
                    <div style={{ 'color': StatusCard[roleKey]?.color }} >
                        {roleKey && StatusCard[roleKey]?.label}
                    </div>
                );
            },
            width: '9.5%'
        },
        {
            Header: 'アクション',
            accessor: '',
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return (
                    <div className='d-flex align-items-center flex-gap-10 justify-content-center flex-wrap'>

                        {original?.status !== StatusCard.NOT_PUBLIC.key ? <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.UN_PUBLIC}
                            type='button'
                            onClick={() => onChangeStatus(original?.id, StatusCard.NOT_PUBLIC.key)}
                            className={classNames('btn-icon btn-icon-blue',
                                (original?.status === StatusCard.WAIT_FOR_DEPOSIT.key ||
                                    original?.status === StatusCard.SENT_MONEY.key
                                ) ? ' invisible' : 'visible')} >
                            <i className="fa-solid fa-user-lock"></i>
                        </button> : <button

                            data-bs-toggle="tooltip"
                            title={TOOLTIP.PUBLIC}
                            type='button'
                            onClick={() => onChangeStatus(original?.id, StatusCard.NO_OWNER.key)}
                            className={classNames('btn-icon btn-icon-green',
                                original?.status === StatusCard.NOT_PUBLIC.key ? ' visible ' : 'invisible')}
                        >
                            <i className="fa-solid fa-user-check"></i>
                        </button>}


                        <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.EDIT}
                            type='button'
                            className='btn-icon btn-icon-orange' onClick={() => {
                                navigate(`${SYSTEM_PATH.CARD_MANAGEMENT}/${original?.id}`)
                            }}>
                            <i className='fa-solid fa-pen-to-square'></i>
                        </button>
                        {userInfo?.role !== ROLE.STAFF.key ? <button
                            data-bs-toggle="tooltip"
                            title={TOOLTIP.DELETE}
                            type='button' className={classNames('btn-icon btn-icon-red',
                                original?.status === StatusCard.WAIT_FOR_DEPOSIT.key ||
                                    original?.status === StatusCard.SENT_MONEY.key ? ' invisible' : 'visible')}
                            onClick={() => onShowConfirmDeleteCard(original?.id)}>

                            <i className='fa-solid fa-trash-can'></i>
                        </button> : <></>}

                    </div>
                );
            },
            width: '9.5%'
        }
    ]

    return (
        <div className='card-screen'>
            <div className='container-title'>
                カード管理</div>
            <div className='container-search'>
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className='row'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>カードID</label>
                            <input {...register('code')} className='col-8' />
                        </div>
                        <div className='d-flex col-6 align-items-center justify-content-end'>
                            <label className='col-3'>ユーザーID</label>
                            <input {...register('userId')} className='col-8' />
                        </div>
                    </div>
                    <div className='row mg-t-20'>

                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>アカウントID</label>
                            <input {...register('accountId')} className='col-8'>
                            </input>
                        </div>
                        <div className='d-flex col-6 align-items-center justify-content-end'>
                            <label className='col-3'>カード種類</label>
                            <select {...register('cardTypeId')} className='col-8'>
                                <option value={''}>すべて</option>
                                {
                                    cardTypeList.map(e =>
                                        <option key={e?.id} value={e?.id} >{e?.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className='row mg-t-20'>
                        <div className='d-flex col-6 align-items-center'>
                            <label className='col-3'>カードステータス</label>
                            <select {...register('status')} className='col-8'>
                                <option value={''} >すべて</option>
                                {
                                    // eslint-disable-next-line max-len
                                    Object.keys(StatusCard).map(e => <option key={StatusCard[e].key} value={StatusCard[e].key} >{StatusCard[e].label}</option>)
                                }
                            </select>
                        </div>
                        <div className={classNames('d-flex col-6 align-items-center justify-content-end')}>
                            <label className='col-3'>支払日</label>
                            <div className='col-8 d-flex align-items-center justify-content-between p-0'>
                                <DateTimeCalendarCustom
                                    startDate={getValues('minTransactionDate') || null}
                                    maxDate={new Date()}
                                    onChange={date => {
                                        setValue('minTransactionDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                                    }}
                                />
                                <div className='d-flex justify-content-center align-items-center px-2'>
                                    <i className='fa-solid fa-minus'></i>
                                </div>
                                <DateTimeCalendarCustom
                                    startDate={getValues('maxTransactionDate') || null}
                                    maxDate={new Date()}
                                    onChange={date => {
                                        setValue('maxTransactionDate', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                                    }}
                                />
                            </div>
                        </div>
                        {/* <SearchByTransactionDate className={'justify-content-end mg-t-20'} /> */}
                    </div>
                    <div className='text-center mg-t-20'>
                        <button type={'submit'} className={'btn btn-bg-cs-1 btn-default  btn-search-back'} disabled={isSubmitting}>
                            <i className='fa-solid fa-magnifying-glass'></i>
                            <span className='mg-l-10'>{TEXT.SEARCH}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div className='container-content'>
                <div className='float-end'>
                    <button type={'button'} className={classNames('btn btn-bg-cs-4 text-white pd-lr-30 mg-r-20 ',
                        selectedCards?.length > 0 ? '' : 'not-allowed')}
                        onClick={onShowModalAssign}>
                        保有者を選択する
                    </button>
                    {userInfo?.role !== ROLE.STAFF.key ? <button type={'button'} className={'btn btn-bg-cs-1 text-white pd-lr-30'}
                        onClick={() => navigate(SYSTEM_PATH.CARD_ADD)}>
                        新しいカードを作る
                    </button> : <></>}
                </div>
                <Table
                    columns={columns}
                    data={cardList || []}
                    disablePaging={false}
                    enableServerSidePaging={true}
                    initialTableState={paging}
                    onFetch={onFetchData}
                    className='lst-card-table'
                    enableSelectRow={true}
                    onSelectedChange={handleSelectCards}
                    selectedRowIds={selectedRowIds}
                    rowKey={'id'}
                    disableSubRowSelect={true}
                    lstDisabledRowSelect={disabledCardList}
                    showDisabledRowSelect={true}
                    useCustomSelectedAll={true}
                    isSelectedAllCustom={isSelectedAllCustom}
                    isSelectedAll={false}
                    onSelectedAllChange={onSelectedAllChange}
                />
            </div>
        </div>
    )
})

export default CardScreen;