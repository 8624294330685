import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { useStore } from '../../core/utils/hook';
import { MSG, SCREEN_MODE, TEXT, SYSTEM_PATH, STATUS_CARD, ACCOUNT_STATUS, STATUS_COMMON } from '../../core/configs/constants';
import { useEffect, useState } from 'react';
import { DateTimeCalendarCustom, FormatNumber, ReactNotifications } from '../../components';
import moment from 'moment';
import { loadFilePreview, loadURLPreview } from '../../core/utils/browser';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import './style.scss';
import { toJS } from 'mobx';
import CardsOfGroup from './components/CardsOfGroup';




const SettingCardScreen = observer((props) => {

    // others
    const navigate = useNavigate();
    const { id } = useParams();


    // props
    const { mode } = props;
    const titlePage = mode === SCREEN_MODE.ADD ? '新しいカードを作る    ' : 'カード編集';

    // store
    const {
        cardStore: { createCard, getCard, card, setAttrObservable, updateCard },
        accountStore: { getAllAccount, accountList },
        cardTypeStore: { getAllCardType, cardTypeList },
        modalStore: { openErrorModal, show, hide }
    } = useStore();

    // state
    const [statusCard, setDataStatusCard] = useState([]);
    const [isCustomCardImage, setIsCustomCardImage] = useState(false);
    const [numberCardsOfGroup, setNumberCardsOfGroup] = useState(1);
    const [isUpdateAll, setIsUpdateAll] = useState(false);

    const validateSchema = yup.object().shape({
        //eslint-disable-next-line max-len
        baseRate: yup.lazy((value) => {
            if (value === '') return yup.string().required(MSG['error.required'])
            return yup.number().nullable().required(MSG['error.required']).typeError(MSG['error.number'])
        }),
        publicAt: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string()
        }),
        cardTypeId: yup.number().required(MSG['error.required']).typeError(MSG['error.number']),
        // accountId: yup.string().required(MSG['error.required']),
        status: yup.number().required(MSG['error.required']).typeError(MSG['error.number']),
        imageUpload: yup.mixed().checkFileImage(MSG['error.image_format']).transform((v) => {
            if (v instanceof FileList) {
                return v.length < 1 ? undefined : v;
            } else {
                return v;
            }
        }),
        image: yup.string().nullable(),
        ...(mode === SCREEN_MODE.ADD ? {
            amount: yup.lazy((value) => {
                if (value === '') return yup.string().required(MSG['error.required'])
                return yup.number()
                    .required(MSG['error.required']).typeError(MSG['error.number']).min(1, MSG['error.number_min_1'])
            })
        } : {}),
        cardPrice: yup.lazy((value) => {
            if (value === '') return yup.string().required(MSG['error.required'])
            return yup.number().nullable().required(MSG['error.required']).typeError(MSG['error.number'])
        }),
        // eslint-disable-next-line max-len
        airdrop: yup.lazy((value) => {
            if (value === '') return yup.string().required(MSG['error.required'])
            return yup.number().nullable().required(MSG['error.required']).typeError(MSG['error.number'])
        }),
        accountId: yup.mixed().when('status', {
            is: STATUS_CARD.OWNING.key,
            then: yup.string().nullable().required(MSG['error.required']),
            otherwise: yup.string().nullable()
        }),
        isLock: yup.boolean()
    })
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        getValues,
        setValue,
        watch
    } = useForm({ resolver: yupResolver(validateSchema), mode: 'onChange' });

    const watchCardtype = watch('cardTypeId');
    const watchStatus = watch('status');
    const watchImageUpload = watch('imageUpload');

    // lifecycle
    useEffect(() => {
        const getData = async () => {
            await getAllCardType({
                size: -1,
                status: STATUS_COMMON.ACTIVE.key
            });
            await getAllAccount({ size: -1, status: ACCOUNT_STATUS.SUBSCRIBED.key })
            if (id) {
                await getCard(id);
            }
        }
        getData();

        return () => {
            setAttrObservable('card', {});
        }
    }, [mode])

    const dataConvert = Object.keys(STATUS_CARD).map((e) => {
        return {
            key: STATUS_CARD[e].key,
            label: STATUS_CARD[e].label
        }
    })

    useEffect(() => {
        const { cardTypeId, image } = card;
        if (mode === SCREEN_MODE.EDIT && !toJS(cardTypeList.filter(e => e.id === cardTypeId)?.[0]?.images)?.includes(image)) {
            setIsCustomCardImage(true);
        } else {
            setIsCustomCardImage(false);
        }
    }, [card])

    useEffect(() => {
        const { code, publicAt, baseRate, cardPrice, airdrop, cardTypeId, status, note, image,
            amount, accountId, isLock, numberCardsOfGroup = 1 } = card;
        reset((mode === SCREEN_MODE.EDIT && id && card.id) ?
            { code, publicAt, baseRate, cardPrice, airdrop, cardTypeId, status, note, image, amount, accountId, isLock }
            :
            {
                amount: 1, accountId: '',
                cardTypeId: getCardTypeDefault(cardTypeList)?.id || null,
                publicAt: moment().format('YYYY/MM/DD HH:mm')
            });

        if (mode === SCREEN_MODE.ADD) {
            setDataStatusCard(dataConvert.slice(0, 3));
        } else if (mode === SCREEN_MODE.EDIT) {
            if (card.status == STATUS_CARD.WAIT_FOR_DEPOSIT.key || card.status == STATUS_CARD.SENT_MONEY.key) {
                setDataStatusCard(dataConvert.slice(3, 5));
            } else {
                setDataStatusCard(dataConvert.slice(0, 3));
            }
            if (numberCardsOfGroup > 1) {
                setNumberCardsOfGroup(numberCardsOfGroup);
                setIsUpdateAll(true);
            }
        }

    }, [card, cardTypeList])

    useEffect(() => {
        const defaultSelect = cardTypeList?.length > 0 && cardTypeList.filter(e => e.id === Number(watchCardtype))?.[0]?.images[0];
        if (getValues('image') !== 'custom-image-upload') {
            setValue('image', !(mode === SCREEN_MODE.EDIT && id && card?.id) || watchCardtype != card?.cardTypeId
                ? defaultSelect : (card?.image || defaultSelect), { shouldValidate: true })
        }
    }, [watchCardtype])

    useEffect(() => {
        if (watchStatus == STATUS_CARD.OWNING.key && accountList.length > 0) {
            $('.selectpicker').selectpicker();
        }
    }, [accountList, watchStatus])

    useEffect(() => {
        if ((watchImageUpload instanceof FileList && watchImageUpload.length > 0)) {
            setValue('image', 'custom-image-upload', { shouldValidate: true });
            loadFilePreview(watchImageUpload[0], 'preview-image');
        } else if (isCustomCardImage) {
            setValue('image', 'custom-image-upload', { shouldValidate: true });
            loadURLPreview(card?.image, 'preview-image');
        }
    }, [watchImageUpload, isCustomCardImage])

    // function
    const getCardTypeDefault = (cardTypes) => {
        if (cardTypes instanceof Array && cardTypes.length > 0) {
            const cardTypeMarkDefault = cardTypes.find(e => e.markDefault);
            if (cardTypeMarkDefault) return cardTypeMarkDefault;
            return cardTypes[cardTypes.length - 1];
        }

        return null;
    }

    const onRemoveImage = () => {
        const defaultSelect = cardTypeList?.length > 0 && cardTypeList.filter(e => e.id === Number(watchCardtype))?.[0]?.images[0];
        setValue('imageUpload', undefined, { shouldValidate: true });
        setValue('image', defaultSelect, { shouldValidate: true });
        if (mode === SCREEN_MODE.EDIT && isCustomCardImage) {
            setIsCustomCardImage(false);
        }
    }

    const showErrorImageUpload = () => {
        openErrorModal('エラー', errors.imageUpload?.message, 'small', onRemoveImage);
    }

    const onShowCardsOfGroup = () => {
        show({
            id: 'modal-card-of-group',
            isOpen: true,
            header: '関連カードの一覧',
            onCancel: () => hide(),
            children: (
                <CardsOfGroup cardIdOfGroup={id} cardGroup={card?.group} />
            ),
            type: 'big'
        })
    }

    const onSubmitSettingCard = async (data) => {
        let res;
        const { publicAt, baseRate, airdrop, cardTypeId, cardPrice, status, note, image, amount, accountId, imageUpload, isLock } = data;
        if (mode === SCREEN_MODE.ADD) {
            res = await createCard({
                publicAt,
                baseRate,
                cardPrice,
                airdrop,
                cardTypeId: +cardTypeId,
                status: +status, note,
                image: imageUpload?.length > 0 && image == 'custom-image-upload' ? imageUpload[0] : image,
                amount
                , ...(status == STATUS_CARD?.OWNING.key ? { accountId: +accountId, isLock } : { isLock: false })
            });
        } else if (mode === SCREEN_MODE.EDIT) {
            res = await updateCard(card?.id, {
                publicAt, baseRate: +baseRate, cardPrice: +cardPrice, airdrop: +airdrop, cardTypeId: +cardTypeId,
                status: (+status || +status == 0) ? +status : card?.status, note,
                image: image == 'custom-image-upload' ? imageUpload?.length > 0 ? imageUpload[0] : card.image : image,
                ...((status == STATUS_CARD?.OWNING.key ||
                    status == STATUS_CARD?.WAIT_FOR_DEPOSIT.key || status == STATUS_CARD?.SENT_MONEY.key) ?
                    { accountId: +accountId ? +accountId : +card?.accountId, isLock } : { isLock: false }),
                isUpdateAll
            });
        }
        if (res) {
            ReactNotifications('success', mode === 0 ? MSG['inform.success.create'] : MSG['inform.success.update']);
            navigate(SYSTEM_PATH.CARD_MANAGEMENT);
        }
    }

    return (
        <div className='setting-card-screen'>
            <div className='container-title'>{titlePage}</div>
            <div className='container-content-form'>
                <form onSubmit={handleSubmit(onSubmitSettingCard)}>
                    <div className='row pd-lr-40'>
                        <div className='col-12'>
                            {mode === SCREEN_MODE.EDIT ? <div className='row mg-b-20'>
                                <label className='col-3'>カードID</label>
                                <div className='col-9 pd-0'>
                                    <span>{card?.code}</span>

                                </div>
                            </div> : ''}
                            <div className='row mg-t-20'>
                                <label className='col-3'>取引所</label>
                                <div className='col-9 pd-0'>
                                    <span>MEXC</span>

                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3'>コイン名</label>
                                <div className='col-9 pd-0'>
                                    <span>Smell Token (SML)</span>

                                </div>
                            </div>
                            {mode === SCREEN_MODE.ADD ? <div className='row mg-t-20'>
                                <label className='col-3 field-required'>発行枚数</label>
                                <div className='col-9 pd-0'>
                                    <FormatNumber
                                        maxValue={999}
                                        className='w-100'
                                        decimalScale={0}
                                        statusDisplay={'input'}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={getValues('amount')}
                                        setvalueOfNumericFormat={(values) => {
                                            // Xử lý giá trị khi thay đổi
                                            setValue('amount', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    {
                                        errors.amount &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.amount?.message}</div>
                                    }
                                </div>
                            </div> : <></>}
                            <div className='row mg-t-20'>
                                <label className='col-3'>発行日時</label>
                                <div className='col-9 pd-0'>
                                    <DateTimeCalendarCustom
                                        startDate={getValues('publicAt') || null}
                                        showTimeSelect={true}
                                        maxDate={new Date()}
                                        onChange={date => {
                                            setValue('publicAt', date ? moment(date).format('YYYY/MM/DD HH:mm') : null, { shouldValidate: true });
                                        }}
                                    />
                                    {
                                        errors.publicAt &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.publicAt?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>基準レート</label>
                                <div className='col-9 pd-0'>
                                    <FormatNumber
                                        statusDisplay={'input'}
                                        maxValue={Math.pow(10, 10) - 1}
                                        className='w-100'
                                        decimalScale={9}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={getValues('baseRate')}
                                        setvalueOfNumericFormat={(values) => {
                                            // Xử lý giá trị khi thay đổi
                                            setValue('baseRate', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    {/* <input {...register('baseRate')} className='w-100' /> */}
                                    {
                                        errors.baseRate &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.baseRate?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>カード価格 (円)</label>
                                <div className='col-9 pd-0'>
                                    <FormatNumber
                                        decimalScale={0}
                                        statusDisplay={'input'}
                                        className='w-100'
                                        maxValue={Math.pow(10, 12) - 1}
                                        // displayType='text'
                                        thousandSeparator={true}
                                        allowNegative={true}
                                        value={getValues('cardPrice')}
                                        setvalueOfNumericFormat={(values) => {
                                            // Xử lý giá trị khi thay đổi
                                            setValue('cardPrice', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    {
                                        errors.cardPrice &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.cardPrice?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>Airdrop</label>
                                <div className='col-9 pd-0'>
                                    <FormatNumber
                                        statusDisplay={'input'}
                                        maxValue={Math.pow(10, 12) - 1}
                                        className='w-100'
                                        decimalScale={1}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={getValues('airdrop')}
                                        setvalueOfNumericFormat={(values) => {
                                            // Xử lý giá trị khi thay đổi
                                            setValue('airdrop', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    {
                                        errors.airdrop &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.airdrop?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>カード種類</label>
                                <div className='col-9 pd-0'>
                                    <select  {...register('cardTypeId')} className='w-100'>
                                        {
                                            cardTypeList?.length > 0 &&
                                            cardTypeList.map(e =>
                                                <option key={e?.id} value={e?.id} >{e?.name}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        errors.cardTypeId &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.cardTypeId?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-24'>
                                <label className='col-3 field-required'>写真を選択
                                </label>
                                <div className='col-9 pd-0'>
                                    <div className='d-flex flex-row align-items-center flex-gap-12 flex-wrap w-100'>
                                        {
                                            cardTypeList?.length > 0 &&
                                            cardTypeList.filter(e => e.id == watchCardtype)?.[0]?.images?.map((item, index) => (
                                                <div key={item} className='mg-r-24 '>
                                                    <label htmlFor={index}>
                                                        <img className='width-120 height-155'
                                                            src={item}></img>
                                                    </label>
                                                    <input
                                                        {...register('image')}
                                                        id={index}
                                                        value={item}
                                                        className='mg-t-8 d-block mx-auto' type={'radio'} />
                                                </div>
                                            ))
                                        }

                                        <div>
                                            {
                                                ((watchImageUpload instanceof FileList && watchImageUpload.length > 0) || isCustomCardImage) &&
                                                (
                                                    <div className='mg-r-24 '>
                                                        <label className='position-relative'>
                                                            <img
                                                                id='preview-image'
                                                                style={{ objectFit: 'fill' }}
                                                                className='width-120 height-155 position-relative' />
                                                        </label>
                                                        <i
                                                            onClick={onRemoveImage}
                                                            role='button'
                                                            className='fa fa-light fa-circle-xmark position-absolute' />
                                                        <input
                                                            {...register('image')}
                                                            className='mg-t-8 d-block mx-auto'
                                                            type={'radio'}
                                                            value={'custom-image-upload'}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                    {
                                        errors.image &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.image?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-24'>
                                <label className='col-3'></label>
                                <div className='col-9 pd-0'>
                                    <div>
                                        <input
                                            id='imageUpload'
                                            type={'file'}
                                            {...register('imageUpload')}
                                            accept='image/png, image/jpeg'
                                            className='w-100'
                                            hidden />
                                        <label
                                            style={{ cursor: 'pointer' }}
                                            htmlFor='imageUpload'
                                            className='text-color-cs-4'>
                                            <i className='fa fa-light fa-upload mg-r-5'></i> デザイン画像アップロード
                                        </label>
                                    </div>
                                    {
                                        errors.imageUpload && <ShowErrorImageUpload showError={showErrorImageUpload} />
                                    }
                                </div>
                            </div>
                            {
                                numberCardsOfGroup > 1 &&
                                <div className='row mg-t-20'>
                                    <label className='col-3 field-required'>一括更新</label>
                                    <div className='col-9 pd-0'>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex align-items-center flex-gap-5'>
                                                <input type='radio' id='update-all' checked={isUpdateAll} onChange={() => setIsUpdateAll(true)} />
                                                <label className='fw-normal' htmlFor='update-all' style={{ 'cursor': 'pointer' }}>はい</label>
                                            </div>
                                            <div className='d-flex align-items-center flex-gap-5 mg-l-30'>
                                                <input type='radio' id='update-only' checked={!isUpdateAll} onChange={() => setIsUpdateAll(false)} />
                                                <label className='fw-normal' htmlFor='update-only' style={{ 'cursor': 'pointer' }}>いいえ</label>
                                            </div>
                                        </div>
                                        <div className='mg-t-5 text-danger'>
                                            <span>【注意】：この機能は同じ日時に発行され、まだ購入完了されていないカードのみに適用されます。</span>
                                            <span className='fw-bolder text-decoration-underline' style={{ 'cursor': 'pointer' }}
                                                onClick={onShowCardsOfGroup}
                                            >
                                                関連カードの一覧を見る
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>カードステータス</label>
                                {(card?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key || card?.status === STATUS_CARD.SENT_MONEY.key) ?
                                    <div className='col-9 pd-0'>
                                        <span>{card?.status === STATUS_CARD.WAIT_FOR_DEPOSIT.key ?
                                            STATUS_CARD.WAIT_FOR_DEPOSIT.label : STATUS_CARD.SENT_MONEY.label}</span>
                                    </div> : <div className='col-9 pd-0'>
                                        <select
                                            {...register('status')}
                                            onChange={(e) => {
                                                setValue('status', e.target.value, { shouldValidate: true });
                                                setValue('isLock', false);
                                            }}
                                            className='w-100'>
                                            {
                                                statusCard.length > 0 &&
                                                // eslint-disable-next-line max-len
                                                statusCard.map(e => <option key={e.key} value={e.key} >{e.label}</option>)
                                            }
                                        </select>
                                        {
                                            errors.status &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.status?.message}</div>
                                        }
                                    </div>}
                            </div>
                            {watchStatus == STATUS_CARD.OWNING.key ? <div className='row mg-t-20'>
                                <label className='col-3 field-required'>保有者を選択</label>
                                <div className='col-9 pd-0'>
                                    <select {...register('accountId')}
                                        className='w-100 selectpicker custom-filter-select' data-live-search="true"
                                        data-none-results-text="データはありません" data-live-search-placeholder="ユーザーネームまたはアカウントIDで検索" >
                                        <option value={''}>何も選択されていません。</option>
                                        {
                                            // eslint-disable-next-line max-len
                                            accountList?.length > 0 &&
                                            accountList.map(e =>
                                                <option key={e?.id} value={e?.id} >{`(${e?.code}) ${e?.userName}`}</option>
                                            )
                                        }
                                    </select>
                                    {
                                        errors.accountId &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.accountId?.message}</div>
                                    }
                                    <div className='mt-2 d-flex align-items-center gap-1' >
                                        <input {...register('isLock')} className='' type='checkbox' role='switch' id='lock' />
                                        <label htmlFor='lock' style={{ cursor: 'pointer' }}>ここをクリックすると、保有アカウントのホルダー画面上のカードがロックされます。</label>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className='row mg-t-20'>
                                <label className='col-3'>メモ</label>
                                <div className='col-9 pd-0'>
                                    <textarea {...register('note')} className='w-100 min-height-150 noScrollTextarea' />
                                    {
                                        errors.note &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.note?.message}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-center align-items-center mg-t-40'>
                        <button type='button' className='btn btn-cs-3 btn-default btn-cancel width-150' onClick={() =>
                            navigate(SYSTEM_PATH.CARD_MANAGEMENT)}>{TEXT.CANCEL}</button>
                        <button type='submit' className='btn-default btn-bg-cs-1 width-150 mg-l-50' disabled={isSubmitting}>
                            {SCREEN_MODE.ADD === mode ? 'カードを作る' : 'カードを作る'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
})

const ShowErrorImageUpload = ({ showError }) => {

    useEffect(() => {
        showError && showError();
    }, [])

    return <></>
}

export default SettingCardScreen;