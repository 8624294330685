import { useForm } from 'react-hook-form';
import { MSG, SIDEBAR_TITLE, TEXT } from '../../core/configs/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { FormatNumber, ModalFooter } from '../../components';
import { useStore } from '../../core/utils/hook';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactNotifications from '../../components/Notifications/ReactNotifications';

const OtherSettingsScreen = observer(() => {
    // store
    const { generalSettingStore: { getOtherSettings, setOtherSettings }, modalStore: { hide, show } } = useStore();

    // state
    const validateOtherSettings = yup.object().shape({
        membershipFee: yup
            .number()
            .required(MSG['error.required'])
            .typeError(MSG['error.number']),
        holderFee: yup
            .number()
            .required(MSG['error.required'])
            .typeError(MSG['error.number']),
        durationCancelMembership: yup
            .number()
            .required(MSG['error.required'])
            .typeError(MSG['error.number']),
        durationNoMembershipFee: yup
            .number()
            .required(MSG['error.required'])
            .typeError(MSG['error.number']),
        lineAccessToken: yup
            .string(),
        inquiryLineAccessToken: yup
            .string(),
        slackInquiryId: yup.string(),
        slackPaymentId: yup.string(),
        slackOAuthToken: yup.string()
    })

    const {
        getValues,
        setValue,
        handleSubmit,
        reset,
        register,
        formState: { errors, isSubmitting }
    } = useForm({ resolver: yupResolver(validateOtherSettings), mode: 'onChange' });

    const [membershipFeeCurrent, setMembershipFeeCurrent] = useState();
    const [holderFeeCurrent, setHolderFeeCurrent] = useState();
    const [autoExportEmailLstCurrent, setAutoExportEmailLstCurrent] = useState([]);

    //life cycle
    useEffect(() => {
        getData();
    }, [])

    //function
    const getData = async () => {
        const res = await getOtherSettings();
        if (res?.ok) {
            const {
                data: {
                    value: {
                        membership_fee,
                        holder_fee,
                        duration_cancel_membership,
                        duration_no_membership_fee,
                        line_access_token,
                        inquiry_line_access_token,
                        auto_export_email_lst,
                        slack_inquiry_id,
                        slack_payment_id,
                        slack_o_auth_token } } } = res;

            setMembershipFeeCurrent(membership_fee);
            setHolderFeeCurrent(holder_fee);
            setAutoExportEmailLstCurrent(auto_export_email_lst.split(', ').filter((item) => item.trim()));

            reset({
                membershipFee: membership_fee,
                holderFee: holder_fee,
                durationCancelMembership: duration_cancel_membership,
                durationNoMembershipFee: duration_no_membership_fee,
                lineAccessToken: line_access_token,
                inquiryLineAccessToken: inquiry_line_access_token,
                slackInquiryId: slack_inquiry_id,
                slackPaymentId: slack_payment_id,
                slackOAuthToken: slack_o_auth_token
            });
        }
    }

    const onSubmit = async (data) => {
        const { membershipFee, holderFee } = data;
        const isUpdateMembershipFee = membershipFee !== membershipFeeCurrent ? true : false;
        const isUpdateHolderFee = holderFee !== holderFeeCurrent ? true : false;
        data = { ...data, autoExportEmailLst: autoExportEmailLstCurrent.join(', ') }
        if (isUpdateMembershipFee || isUpdateHolderFee) {
            show({
                id: 'modal-alert',
                isOpen: true,
                header: '確認',
                onCancel: () => {
                    hide();
                },
                children: (
                    <div className='font-bold max-width-960 pd-10'>
                        {
                            isUpdateMembershipFee ?
                                (
                                    isUpdateHolderFee ?
                                        <div className='mg-t-10'>会費およびHolder追加料を変更すると全ユーザーに自動メールが送信されますのでよろしいでしょうか？</div>
                                        : <div className='mg-t-10'>会費を変更すると全ユーザーに自動メールが送信されますのでよろしいでしょうか？</div>
                                ) : (
                                    isUpdateHolderFee ?
                                        <div className='mg-t-10'>Holder追加料を変更すると全ユーザーに自動メールが送信されますのでよろしいでしょうか？</div>
                                        : <div></div>
                                )
                        }

                        <ModalFooter key={'modal-footer'}
                            saveButtonText={TEXT.YES}
                            cancelButtonText={TEXT.NO}
                            onConfirm={async () => {
                                const res = await setOtherSettings({ value: data });
                                if (res?.ok) {
                                    getData();
                                    ReactNotifications('success', MSG['inform.success.update']);
                                    hide();
                                }
                            }}
                            onCancel={() => {
                                hide();
                            }}
                            saveButtonClass='btn-bg-cs-1'
                        />
                    </div>
                ),
                type: 'small'
            });
        } else {
            const res = await setOtherSettings({ value: data });
            if (res?.ok) {
                getData();
                ReactNotifications('success', MSG['inform.success.update']);
            }
        }
    }

    return (
        <div className='other-setting-screen'>
            <div className='container-title'>{SIDEBAR_TITLE.OTHER_SETTING}</div>
            <div className='container-content'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className='border-bottom border-dark mg-b-20 fw-bold'>月額料</div>
                        <div className='row align-items-center mg-t-20'>
                            <label className='col-2-5 fw-normal'>会費</label>
                            <div className='col-8'>
                                <div className='w-100 d-flex align-items-center flex-gap-10'>
                                    <FormatNumber
                                        statusDisplay='input'
                                        minValue={1}
                                        maxValue={Math.pow(10, 9) - 1}
                                        className='col-8'
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={getValues('membershipFee')}
                                        setvalueOfNumericFormat={(values) => {
                                            setValue('membershipFee', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    <span className='col-2'> /月</span>
                                </div>
                                {
                                    errors?.membershipFee &&
                                    <div className='text-danger fs-error mg-t-5'>
                                        {errors?.membershipFee?.message}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row align-items-center mg-t-20'>
                            <label className='col-2-5 fw-normal'>Holder追加料</label>
                            <div className='col-8'>
                                <div className='w-100 d-flex align-items-center flex-gap-10'>
                                    <FormatNumber
                                        statusDisplay='input'
                                        minValue={1}
                                        maxValue={Math.pow(10, 9) - 1}
                                        className='col-8'
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        value={getValues('holderFee')}
                                        setvalueOfNumericFormat={(values) => {
                                            setValue('holderFee', values.value, { shouldValidate: true });
                                        }}
                                    />
                                    <span className='col-2'> /月</span>
                                </div>
                                {
                                    errors?.holderFee &&
                                    <div className='text-danger fs-error mg-t-5'>
                                        {errors?.holderFee?.message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='border-bottom border-dark fw-bold mg-t-80'>削除猶予期間</div>
                        <div className='mg-b-20 mg-t-5'>保有されているカードをHolder一覧からすべて削除するまで削除猶予期間を設定してください。</div>
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>有料会員解約・退会・強制解約の場合（日）</label>
                            <div className='col-8'>
                                <FormatNumber
                                    statusDisplay='input'
                                    minValue={1}
                                    maxValue={Math.pow(10, 4) - 1}
                                    className='col-8'
                                    decimalScale={0}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    value={getValues('durationCancelMembership')}
                                    setvalueOfNumericFormat={(values) => {
                                        setValue('durationCancelMembership', values.value, { shouldValidate: true });
                                    }}
                                />
                                {
                                    errors?.durationCancelMembership &&
                                    <div className='text-danger fs-error mg-t-5'>
                                        {errors?.durationCancelMembership?.message}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row align-items-center mg-t-20'>
                            <label className='col-2-5 fw-normal'>月額料を支払いできない場合（日）</label>
                            <div className='col-8'>
                                <FormatNumber
                                    statusDisplay='input'
                                    minValue={1}
                                    maxValue={Math.pow(10, 4) - 1}
                                    className='col-8'
                                    decimalScale={0}
                                    thousandSeparator={false}
                                    allowNegative={false}
                                    value={getValues('durationNoMembershipFee')}
                                    setvalueOfNumericFormat={(values) => {
                                        setValue('durationNoMembershipFee', values.value, { shouldValidate: true });
                                    }}
                                />
                                {
                                    errors?.durationNoMembershipFee &&
                                    <div className='text-danger fs-error mg-t-5'>
                                        {errors?.durationNoMembershipFee?.message}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <div className='border-bottom border-dark fw-bold mg-t-80'>LINE通知設定</div>
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>LINEアクセストークン (取引用)</label>
                            <div className='col-8'>
                                <textarea
                                    {...register('lineAccessToken')}
                                    className='col-8 reset-auto-fill min-height-120' />
                            </div>
                        </div>
                        {
                            errors?.lineAccessToken &&
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6 text-danger fs-error'>{errors?.lineAccessToken?.message}</div>
                            </div>
                        }
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>LINEアクセストークン (お問合せ用)</label>
                            <div className='col-8'>
                                <textarea
                                    {...register('inquiryLineAccessToken')}
                                    className='col-8 reset-auto-fill min-height-120' />
                            </div>
                        </div>
                        {
                            errors?.inquiryLineAccessToken &&
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6 text-danger fs-error'>{errors?.inquiryLineAccessToken?.message}</div>
                            </div>
                        }
                    </div> */}
                    <div>
                        <div className='border-bottom border-dark fw-bold mg-t-80'>SLACK通知設定</div>
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>Slack認証トークン</label>
                            <div className='col-8'>
                                <input
                                    {...register('slackOAuthToken')}
                                    className='col-8 reset-auto-fill min-height-120' />
                            </div>
                        </div>
                        {
                            errors?.slackOAuthToken &&
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6 text-danger fs-error'>{errors?.slackOAuthToken?.message}</div>
                            </div>
                        }
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>Slack宛先ID (取引用)</label>
                            <div className='col-8'>
                                <textarea
                                    {...register('slackPaymentId')}
                                    className='col-8 reset-auto-fill min-height-120' />
                            </div>
                        </div>
                        {
                            errors?.slackPaymentId &&
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6 text-danger fs-error'>{errors?.slackPaymentId?.message}</div>
                            </div>
                        }
                        <div className='row mg-t-20'>
                            <label className='col-2-5 fw-normal'>Slack宛先ID (お問合せ用)</label>
                            <div className='col-8'>
                                <textarea
                                    {...register('slackInquiryId')}
                                    className='col-8 reset-auto-fill min-height-120' />
                            </div>
                        </div>
                        {
                            errors?.slackInquiryId &&
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-6 text-danger fs-error'>{errors?.slackInquiryId?.message}</div>
                            </div>
                        }
                    </div>
                    <AutoExportEmailForm
                        autoExportEmailLstCurrent={autoExportEmailLstCurrent}
                        setAutoExportEmailLstCurrent={setAutoExportEmailLstCurrent} />
                    <div className='text-center mg-t-100'>
                        <button type={'submit'} className={'btn-bg-cs-1 btn-default'} disabled={isSubmitting}>
                            <span>{TEXT.UPDATE}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default OtherSettingsScreen;

const AutoExportEmailForm = ({ autoExportEmailLstCurrent, setAutoExportEmailLstCurrent }) => {

    const validateAutoExportEmailForm = yup.object().shape({
        autoExportEmail: yup
            .string()
            .required(MSG['error.required'])
            .email(MSG['error.email_format'])
            .test(
                'is-not-duplicate-email',
                'このメールアドレスは既に追加されました。',
                (value) => {
                    return !autoExportEmailLstCurrent.includes(value);
                }
            )
    })

    const {
        register,
        getValues,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validateAutoExportEmailForm), mode: 'onChange' });

    const onSubmit = () => {
        const newEmail = getValues('autoExportEmail');
        if (newEmail) {
            setAutoExportEmailLstCurrent((prev) => [...prev, newEmail]);
            reset({ autoExportEmail: '' });
        }
    }

    const handleClick = () => {
        handleSubmit(onSubmit)(); // Gọi handleSubmit để thực hiện xác thực và gọi onSubmit nếu hợp lệ
    };

    return (
        <div>
            <div className='border-bottom border-dark fw-bold mg-t-80'>自動メール設定（毎月ユーザー一覧送信用）</div>
            <div className='mg-b-20 mg-t-5'>こちらで、管理者は毎月エクスポートされるユーザー一覧ファイルを電子メールで受け取る方のリストを設定します。</div>
            <div className='row mg-t-20'>
                <label className='col-2-5 fw-normal'>受信者のメールアドレス</label>
                <div className='col-8 d-flex flex-row' style={{ gap: '16px' }}>
                    <input {...register('autoExportEmail')} className='col-8' />
                    <div className='col-1-5 w-100'>
                        <button
                            onClick={handleClick}
                            type='button'
                            className='btn-default btn-search-back h-100 w-100 flex justify-content-center align-items-center'>
                            追加
                        </button>
                    </div>
                </div>
            </div>
            <div className='row mg-t-5'>
                <div className='col-2-5'></div>
                {
                    errors?.autoExportEmail &&
                    <div className='col-8 text-danger fs-error'>{errors?.autoExportEmail?.message}</div>
                }
            </div>
            <div className='row mg-t-20'>
                <label className='col-2-5'></label>
                <div className='col-8'>
                    <div className='row g-3'>
                        {
                            autoExportEmailLstCurrent?.length > 0 && autoExportEmailLstCurrent.map((item, index) => (
                                <div key={index} className='col-4'>
                                    <EmailItem index={index} item={item} setAutoExportEmailLstCurrent={setAutoExportEmailLstCurrent} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const EmailItem = ({ index, item, setAutoExportEmailLstCurrent }) => {

    const onRemoveEmail = () => {
        setAutoExportEmailLstCurrent((prev) => {
            return prev.filter((_, i) => i !== index)
        });
    }

    return (
        <button
            type='button'
            className='btn-default btn-bg-cs-medium-gray-opacity d-flex align-items-center justify-content-between w-100 height-35 pd-16'
            style={{ cursor: 'auto' }}>
            <span className='fw-bold text-color-cs-5'>{item}</span>
            <i className='fa-solid fa-xmark text-color-cs-5' style={{ cursor: 'pointer' }} onClick={onRemoveEmail}></i>
        </button>
    )
}
