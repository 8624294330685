import { observer } from 'mobx-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import '../style.scss';
import { FormatNumber } from '../../../components';
import { MSG, STATUS_CARD, SYSTEM_PATH } from '../../../core/configs/constants';
import { useStore } from '../../../core/utils/hook';
import { flowResult } from 'mobx';

const CardsInPossession = observer((props) => {
    //props
    const { listCardOwner } = props
    const navigate = useNavigate();
    const { id } = useParams();

    // store
    const {
        modalStore: {
            openWarningModal, openErrorModalWithFb, hideAll, openErrorModal
        },
        cardStore: { updateStatusLockCard },
        accountStore: { getlistCardOfMember }
    } = useStore()

    const confirmUpdateLockStatusOfCard = (cardId, isLock, accountId) => {
        const msg = isLock ? MSG['modal.confirm_unlock_card']
            : MSG['modal.confirm_lock_card'];
        openWarningModal(msg, () => onUpdateStatusLockCard(cardId, { isLock: !isLock, accountId }));
    }

    const onUpdateStatusLockCard = async (cardId, payload) => {
        const res = await flowResult(updateStatusLockCard(cardId, payload, handleError));
        if (res) {
            getlistCardOfMember(id, { status: [STATUS_CARD.OWNING.key] })
        }
    }

    const handleError = (error) => {
        hideAll();
        if (error.status == 404) {
            openErrorModalWithFb('エラー', error?.data?.message, () => {
                getlistCardOfMember(id, { status: [STATUS_CARD.OWNING.key] })
            }, '閉じて、ページを再読み込む')
        } else {
            openErrorModal('エラー', error?.data?.message || MSG['api.response.no_message'])
        }
        
    }

    return (
        <div className='d-flex flex-wrap flex-gap-5 w-full card-purchase-screen'>
            {listCardOwner?.length > 0 ?
                listCardOwner.map((item) => (
                    <div key={item?.id} className='d-flex col-6-40 gap-3 rounded p-3 bg-light'>
                        <div className='card-hold-img'>
                            <img className='width-180 height-154' src={item?.image}></img>
                            {
                                item.isLock && (
                                    <div className='card-lock px-2 height-25 d-flex gap-1 align-items-center rounded'>
                                        <i class="fas fa-lock"></i>
                                        <span>ロック済み</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className='flex-grow-1 px-1'>
                            <div className='d-flex flex-wrap border-cs-dashed-bottom'>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <label className='text-color-cs-label-1'>管理番号</label>
                                    <Link to={`${SYSTEM_PATH.CARD_MANAGEMENT}/${item?.id}`} target="_blank">{item?.code}</Link>
                                </div>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>発行日時</span>
                                    <span >{item?.publicAt}</span>
                                </div>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>Airdrop</span>
                                    {(item?.airdrop || item?.airdrop === 0) ?
                                        <span>
                                            <FormatNumber statusDisplay='text' value={item?.airdrop} suffix=' SML' />
                                        </span> : ''}
                                </div>
                                {item?.variationRate?.toFixed(2) < 0 ?
                                    <div className='d-flex flex-column col-6 mb-2 text-danger'>
                                        <div className='text-color-cs-label-1'>騰落率</div>
                                        <span className='font-bold'>
                                            <i className="fa-solid fa-down-long"></i>{' '}
                                            {item?.variationRate?.toFixed(2)} %</span>
                                    </div> : <div className='d-flex flex-column col-6 mb-2 text-success'>
                                        <div className='text-color-cs-label-1' >騰落率</div>
                                        <span className='font-bold'>
                                            <i className="fa-solid fa-up-long"></i>
                                            {' '}+{item?.variationRate?.toFixed(2)} %</span>
                                    </div>}
                            </div>
                            <div className='d-flex flex-wrap mg-t-10'>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>基準レート</span>
                                    <span >{item?.baseRate} USDT</span>
                                </div>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>カード価格</span>
                                    <span>
                                        <FormatNumber statusDisplay='text' value={item?.cardPrice} suffix=' 円' />
                                    </span>
                                </div>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>現在レート</span>
                                    <span >{(+item?.currentRate)?.toFixed(6)} USDT</span>
                                </div>
                                <div className='d-flex flex-column col-6 mb-2'>
                                    <span className='text-color-cs-label-1'>現在価格</span>
                                    <span>
                                        <FormatNumber statusDisplay='text' value={item?.currentPrice} decimalScale={0} suffix=' 円' />
                                    </span>
                                </div>
                            </div>

                            <div className='mt-4 d-flex justify-content-end w-100 height-50'>
                                <button
                                    type='button'
                                    onClick={() => { confirmUpdateLockStatusOfCard(item.id, item.isLock, item.accountId) }}
                                    className='h-100 py-1 px-2 btn-lock d-flex gap-2 align-items-center rounded'>
                                    <div className='bg-cs-7 height-40 width-40 d-flex align-items-center justify-content-center rounded'>
                                        {
                                            item?.isLock ? (
                                                <i class="fas fa-lock-open fs-4"></i>
                                            ) : (
                                                <i class="fas fa-lock fs-4"></i>
                                            )
                                        }

                                    </div>
                                    <p className='p-0 m-0 text-black'>{item?.isLock ? 'カードをアンロック' : 'カードをロック'}</p>
                                </button>
                            </div>
                        </div>


                    </div>
                )) : <div className='text-center fs-default'>{MSG['inform.no_data']}</div>}
        </div>

    )
})

export default CardsInPossession;